<template>
<!--  <div class="bottom_first back-common" :class="{'center_bottom':footerList.length<=0}">-->
<!--    <div class="bottom_first_content">-->
<!--      <ul>-->
<!--        <li @click="chooseBottom(index)" v-for="(item,index) in footerList" :key="index"-->
<!--            :class="{'bottom_first_content_color':currentTag === index}">-->
<!--          {{item.typeName}}-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->

<!--    <div class="bottom_second_content" v-if="footerList.length>0">-->
<!--      <span v-for="(item,index) in footerList.length>0&& footerList[currentTag].link"-->
<!--            :key="index" @click="openNewWindow(item.target)" class="bottom-span-hover">{{item.name}}</span>-->
<!--    </div>-->
<div v-html="footInfo"></div>
<!--    <div-->
<!--      style="min-height:148px;margin:0 auto ;font-size:14px;color:rgb(200,200,200);height: auto;line-height: 26px;padding:35px 0;width: 1200px;text-align: center">-->
<!--      主办单位：宁夏中宁枸杞产业创新研究院、南京中高知识产权股份有限公司、宁夏中宁枸杞产业集团有限公司<br>-->
<!--      地址：宁夏回族自治区中卫市中宁县科技局<br>-->
<!--      <a href="https://beian.miit.gov.cn/" target="_blank" style="color: rgb(220,220,220)">   Copyright©2021 南京中高 All Rights Reserved. 南京中高知识产权股份有限公司 版权所有-->
<!--        工业和信息化部备案管理系统网站 宁B2-20090191-18</a>-->


<!--    </div>-->

<!--  </div>-->
</template>

<script>
  import {getIndexUrl} from "../../../plugins/api/indexApi";
  import {openNewWindow} from "../../../plugins/tools/util";
  import {footInfo} from "../../../plugins/tools/footer";

  export default {
    name: 'footerComponent',
    data() {
      return {
        currentTag: 0,
        footerList: [],
        footInfo:""
      }
    },
    mounted() {
      this.getFooterUrl();
      this.footInfo = footInfo
    },
    methods: {
      async getFooterUrl() {
        const json = await getIndexUrl();
        if (json && json.code === 0) {
          this.footerList = json.result;
        }

      },
      chooseBottom(index) {
        this.currentTag = index
      },
      openNewWindow,
    }
  }
</script>

<style scoped>

.bottom-span-hover:hover {
  color: #FF6A00;
}
  .bottom_second_content span {
    height: 35px;
    line-height: 35px;
    margin-right: 20px;
    font-size: 14px;
    color: rgb(200, 200, 200);
  }

  .bottom_second_content {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    height: auto;
    border-bottom: 1px solid rgb(56, 58, 66);
    cursor: pointer;
  }

  .bottom_first_content_color {
    color: rgb(255, 106, 0) !important;
  }

  .bottom_first_content {
    width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid rgb(56, 58, 66);

  }

  .bottom_first_content ul li:hover {
    cursor: pointer;
  }

  .bottom_first_content ul li {
    display: inline-block !important;
    width: auto;
    height: 75px;
    line-height: 75px;
    margin-right: 50px;
    font-size: 16px;
    font-family: "Microsoft YaHei";
    color: white;
  }

  .bottom_second {
    height: 900px;
    background-color: #282828;
  }

  .bottom_first {
    height: auto!important;
    background-color: #252525;
    margin-top: 50px;
  }

</style>
